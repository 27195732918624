<template>
  <v-col cols="6">
    <div class="form-group">
      <label for="">
        <span> {{ label }}:</span>
      </label>
      <div class="field-wrap">
        <datetime
          zone="local"
          value-zone="local"
          type="time"
          :value="value"
          @input="formatValue"
          input-class="form-control"
          format="HH:mm"
        ></datetime>
      </div>
    </div>
  </v-col>
</template>

<script>
import moment from "moment";

export default {
  name: "InputTime",
  props: ["label", "value"],
  methods: {
    formatValue(value) {
      const date = moment(value, "YYYY-MM-DDTHH:mm:ss.SSS");
      let result = value;

      if (date.isValid()) {
        result = date.format("HH:mm");
      }
      this.$emit("input", result);
    }
  }
};
</script>
