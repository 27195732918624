<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, k) in error_messages">
          <span v-bind:key="k">
            <li v-for="(message, i) in error" v-bind:key="i">{{ message }}</li>
          </span>
        </template>
      </ul>
    </div>

    <v-stepper v-model="step" alt-labels>
      <v-stepper-header class="p-3">
        <h1>Работно време</h1>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" color="grey lighten-1">
            <v-row>
              <InputTime v-model="workingTime.startTime" label="Начало" />
              <InputTime label="Край" v-model="workingTime.endTime" />
            </v-row>

            <v-btn
              @click="saveWorkingTime()"
              class="btn btn-success btn-elevate kt-login__btn-primary text-white"
              >{{ $t("BUTTON.SAVE") }}
            </v-btn>
            <v-progress-circular
              class="just-center"
              indeterminate
              color="primary"
              v-if="isLoadingWorkingTime"
            ></v-progress-circular>

            <br />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import {
  GET_WORKING_TIME,
  UPDATE_WORKING_TIME
} from "@/store/workingTime.module.js";

import _ from "lodash";

import InputTime from "@/components/InputTime";

import { mapGetters, mapState } from "vuex";

export default {
  name: "WorkBreakUpdate",

  components: { InputTime },
  computed: {
    ...mapGetters(["isLoadingWorkingTime"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  data() {
    return {
      step: 1,
      error_messages: [],
      addNew: false,
      model: "working_time",
      workingTime: {
        startTime: null,
        endTime: null
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      let current_page = "Промени";
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Работно време", route: { name: "workingTime" } },
        { title: current_page }
      ]);
    }, 300);

    this.getData();
  },
  methods: {
    async saveWorkingTime() {
      let vm = this;

      vm.error_messages = [];

      vm.$store
        .dispatch(UPDATE_WORKING_TIME, { payload: vm.workingTime })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },

    getData: function() {
      let vm = this;
      this.error_messages = [];

      this.$store
        .dispatch(GET_WORKING_TIME)
        .then(data => {
          vm.$nextTick(function() {
            vm.workingTime = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "workingTimeList" });
          }
        });
    }
  }
};
</script>
